<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动区域">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="起止时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="微信批次号"
                    class="width300"
                    prop="wx_stock_id">
        <el-input type="text"
                  placeholder="请输入微信批次号"
                  v-model="form.wx_stock_id"></el-input>
        <p class="input-tip">仅为方便后台管理识别</p>
      </el-form-item>
      <el-form-item label="关联特邀活动"
                    prop="guest_id">
        <el-select class="width300"
                   v-model="form.guest_id"
                   filterable
                   remote
                   placeholder="请输入特邀活动Id进行搜索"
                   :remote-method="remoteMethod"
                   :loading="selectLoading">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指定邮储卡">
        <el-switch v-model="form.is_postal"></el-switch>
      </el-form-item>
      <el-form-item label="总预算"
                    class="width300"
                    prop="total_money">
        <el-input type="text"
                  placeholder="请输入总预算"
                  v-model.number="form.total_money"></el-input>
      </el-form-item>
      <el-form-item label="每日预算"
                    class="width300"
                    prop="today_money">
        <el-input type="text"
                  placeholder="请输入每日预算"
                  v-model="form.today_money"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setActivity, getActivity, fetchGuests } from '@/api/market'
import Area from '@/components/Area'
export default {
  name: 'SetPayment',
  data () {
    return {
      selectLoading: false,
      options: [],
      areaAddress: {},
      dateTime: [],
      form: {
        type: 'REDUCE',
        title: '',
        started_at: '',
        ended_at: '',
        list_city: '',
        list_province: '',
        list_district: '',
        list_net: '',
        wx_stock_id: '',
        is_postal: 0,
        guest_id: '',
        total_money: 0,
        today_money: 0
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        wx_stock_id: [
          { required: true, message: '请输入微信批次号', trigger: 'blur' }
        ],
        guest_id: [
          { required: true, message: '请选择关联特邀活动', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const form = Object.assign({}, this.form)
          form.total_money = form.total_money * 100
          form.today_money = form.today_money * 100
          const res = await setActivity(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('payment')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        this.form = activity
        this.form.activity_id = id
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.form.total_money = this.form.total_money / 100
        this.form.today_money = this.form.today_money / 100
        this.dateTime = [activity.started_at, activity.ended_at]
        this.remoteMethod(this.form.guest_id)
      }
    },
    async remoteMethod (query) {
      if (query !== '') {
        this.selectLoading = true
        const res = await fetchGuests({
          page: 1,
          size: 5,
          id: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          const list = []
          res.data.guests.map((item) => {
            list.push({
              value: item.id,
              label: item.title
            })
          })
          this.options = list
        }
      } else {
        this.options = []
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
